import { ISuggestedData } from 'components/Cards/types';
import { PathTranslations } from 'service/localization/getPathTranslations';
import type { ImageDetailType, ImageType } from 'types/ICardData';

// ! TODO: THIS FILE NEEDS TO BE REFACTORED TO HANDLE RELATED DATA WITH LESS OVERHEAD

// ! TODO: NEEDS TO BE REFACTORED TO BE MORE USEFUL AND HANDLE SPECIFIC CASES
// ! RATHER THAN USING THIS FOR EVERYTHING, WE NEED TO HAVE A TRANSFORMER
// ! FOR EACH SPECIFIC CASE  OF DATA RATHER THAN USING MULTIPLE TRANSFORMERS
// ! ON TOP OF EACH OTHER

const generateRelatedLinks = (
  pathTranslations: PathTranslations,
  item: any,
  cardType: string,
): string => {
  if (cardType === 'recipe') {
    return `${pathTranslations.recipe}${item?.Slug || item?.Id}`;
  }
  if (cardType === 'tipsAndTricks') {
    return `${pathTranslations.tipAndTrick}${item?.Slug || item?.Id}`;
  }
  if (cardType === 'product') {
    return `${pathTranslations.product}${item?.Slug || item?.Id}`;
  }

  return `/${cardType}/${item?.Slug}`;
};

const getImagesFromRecipe = ({ Images }: { Images: any[] }) => {
  const finalImageList = {
    desktop: '',
    mobile: '',
    tablet: '',
    hero: '',
    gridRegular: '',
    gridWide: '',
    gridLarge: '',
  };
  const finalImages = Images?.[0]?.Formats?.filter((images: ImageType) => {
    let imagesList;
    if (
      images.Width === 964 ||
      images.Width === 636 ||
      images.Width === 302 ||
      images.Width === 1272 ||
      images.Width === 360 ||
      images.Width === 750 ||
      images.width === 964 ||
      images.width === 636 ||
      images.width === 302 ||
      images.width === 1272 ||
      images.width === 320 ||
      images.width === 1440
    ) {
      imagesList = images;
    } else if (!images.Width && !images.width) {
      imagesList = images;
    }
    return imagesList;
  });
  // eslint-disable-next-line max-len
  finalImages?.forEach((imageDetail: ImageType) => {
    // If related recipes in product page
    if (
      imageDetail?.Url &&
      typeof imageDetail.Url === 'string' &&
      (imageDetail?.Url?.includes('.jpg') ||
        imageDetail?.Url?.includes('png') ||
        imageDetail?.Url?.includes('webp'))
    ) {
      if (imageDetail?.Width === 964) {
        finalImageList.desktop = imageDetail?.Url;
      }
    } else if (imageDetail?.Width === 636) {
      finalImageList.tablet = imageDetail?.Url;
    } else if (imageDetail?.Width === 302 || imageDetail?.Width === 320) {
      finalImageList.mobile = imageDetail?.Url;
    } else if (imageDetail?.Width === 1272 || imageDetail?.Width === 1440) {
      finalImageList.hero = imageDetail?.Url;
    } else if (imageDetail?.Url?.[0]?.url) {
      imageDetail?.Url?.map((imageDetailItem: ImageDetailType) => {
        if (imageDetailItem?.width === 964) {
          finalImageList.desktop = imageDetailItem?.url;
        } else if (imageDetailItem?.width === 636) {
          finalImageList.tablet = imageDetailItem?.url;
        } else if (imageDetailItem?.width === 302) {
          finalImageList.mobile = imageDetailItem?.url;
        } else if (imageDetailItem?.width === 1272) {
          finalImageList.hero = imageDetailItem?.url;
        } else if (imageDetailItem?.width === 360 && imageDetailItem?.height === 400) {
          finalImageList.gridRegular = imageDetailItem?.url;
        } else if (imageDetailItem?.width === 750 && imageDetailItem?.height === 400) {
          finalImageList.gridWide = imageDetailItem?.url;
        } else if (imageDetailItem?.width === 750 && imageDetailItem?.height === 910) {
          finalImageList.gridLarge = imageDetailItem?.url;
        } else {
          finalImageList.hero = imageDetailItem?.url;
        }
      });
    }
  });

  return finalImageList;
};

const getRelatedData = (
  data: any,
  pathTranslations: PathTranslations,
  cardType: string,
  linkText?: string,
): ISuggestedData[] => {
  const relatedData = data?.map((item: any) => {
    const prepTime = (item?.PreparationTimeInMinutes || 0) + (item?.BakingTimeInMinutes || 0);
    const totalTime =
      item?.TotalTimeInMinutes ||
      (item?.PreparationTimeInMinutes || 0) + (item?.BakingTimeInMinutes || 0) ||
      0;
    const finalImageList = getImagesFromRecipe(item);
    // * need to pass fallback null in case of underfiened for serverside rendering.
    return {
      objectID: item.objectID,
      queryID: item.queryID,
      id: item?.Id || null,
      title: item?.Title || null,
      description: item?.ShortDescription || null,
      categoryId: item?.Category?.Id || null,
      category: item?.Category?.Title || null,
      // ! subCategory: item.Category?.Title,
      images: finalImageList,
      video: item?.VideoInstruction || null,
      information: {
        preparationTime: prepTime > 0 && `${prepTime} Min`,
        rating: item?.Rating || '0.0',
        difficulty: typeof item?.Difficulty === 'number' ? item?.Difficulty : null,
        totalTime: `${totalTime || prepTime} Min` || null,
      },
      slug: item?.Slug || null,
      link: {
        url: generateRelatedLinks(pathTranslations, item, cardType) || null,
        displayName: linkText || 'Learn More',
      },
    };
  });
  return relatedData;
};

export const RelatedDataService = {
  getRelatedData,
  getImagesFromRecipe,
};
